<template>
  <div class="card-container">
    <div class="card-content">
      <header class="main-header">
        <h1 class="name">Andrea Cristaudo</h1>
        <h2 class="role">Consultant developer</h2>
      </header>

      <div class="content-container">
        <div class="content">
          <div class="intro">
            <p>
              Aiuto le aziende e i privati a <strong>realizzare idee di business</strong> in ambito web
              proponendo le soluzioni più adatte alle loro esigenze.
            </p>
            <p>
              Seguo <strong>l'intero processo di sviluppo</strong>: analisi, pianificazione, realizzazione,
              configurazione, messa in opera, monitoraggio e manutenzione.
            </p>
            <p>
              Sono sempre alla ricerca del progetto giusto che possa essere valorizzato dal mio contributo.
            </p>
          </div>

          <div class="contacts">
            <a href="mailto:andrea@cristaudo.eu" target="_blank" class="btn btn-primary" title="Inviami un'email">
            <span class="btn-content">
              Contattami
            </span>
            </a>

            <ul class="contacts-links p-0 list-unstyled">
              <li class="contacts-link">
                <a href="https://goo.gl/maps/c1q6mmxGv1pQnKoC6" rel="noreferrer" target="_blank" title="Vienimi a trovare al Talent Garden Pisa">
                  <i class="icon icon-pin">
                    <span class="visually-hidden">Vienimi a trovare al Talent Garden Pisa</span>
                  </i>
                </a>
              </li>
              <li class="contacts-link">
                <a href="tel:00393498759707" title="Chiamami">
                  <i class="icon icon-phone">
                    <span class="visually-hidden">Chiamami</span>
                  </i>
                </a>
              </li>
              <li class="contacts-link">
                <a href="https://twitter.com/a_cristaudo" rel="noreferrer" target="_blank" title="Seguimi su Twitter">
                  <i class="icon icon-twitter">
                    <span class="visually-hidden">Seguimi su Twitter</span>
                  </i>
                </a>
              </li>
              <li class="contacts-link">
                <a href="https://www.linkedin.com/in/andrea-cristaudo/" rel="noreferrer" target="_blank" title="Seguimi su LinkedIn">
                  <i class="icon icon-linkedin">
                    <span class="visually-hidden">Seguimi su LinkedIn</span>
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="content-filler"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.card-container {
  margin: 20px 20px;
  padding: 0;
  padding-bottom: math.div(9, 14) * 100%;
  position: relative;
  min-height: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  background: $color-yellow-bg no-repeat bottom right url($path-images + "/andrea-cristaudo-illustration.svg");
  background-size: 100% auto;
  box-shadow: 0 0 21px rgba(0, 0, 0, 0.25);
  min-width: 280px;

  @include media-breakpoint-up("md") {
    padding-bottom: math.div(9, 20) * 100%;
    margin: 50px 60px;
  }

  @include media-breakpoint-up("lg") {
    margin: 90px 100px;
  }
}

.main-header {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  padding: 0 10px;

  @include media-breakpoint-up("md") {
    padding: 0 20px;
  }

  .name {
    color: $color-green;
    font-family: Montserrat, sans-serif;
    position: relative;
    margin: 0 0 5px;
    padding-top: 130px;
    background: no-repeat transparent center top 30px url($path-images + "/logo.svg");
    background-size: 80px;
    font-size: 23px;
    line-height: 30px;

    @include media-breakpoint-up("md") {
      font-size: 45px;
      line-height: 50px;
    }

    @include media-breakpoint-up("lg") {
      font-size: 70px;
      line-height: 90px;
    }
  }

  .role {
    position: relative;
    font-size: 14px;
    line-height: 21px;
    font-family: Oswald, sans-serif;
    margin-bottom: 25px;

    @include media-breakpoint-up("md") {
      font-size: 26px;
      line-height: 35px;
    }

    @include media-breakpoint-up("lg") {
      font-size: 34px;
      line-height: 50px;
    }

    &::before {
      display: inline-block;
      content: '<';
    }

    &::after {
      display: inline-block;
      content: '>';
    }
  }
}

.intro {
  text-align: center;
  font-size: 18px;
  padding: 0 20px;
  margin: 0;

  @include media-breakpoint-up("md") {
    font-size: 24px;
    line-height: 32px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contacts {
  margin: 35px;
  text-align: center;

  .btn {
    display: inline-block;
    width: 100%;
    max-width: 280px;
    padding: 10px;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    background: rgba(94, 192, 191, .4);
    box-shadow: 0 0 6px rgba(64, 164, 163, .4);
    border-radius: 38px;

    .btn-content {
      display: block;
      background: #5EC0BF;
      box-shadow: 0 0 6px #40A4A3;
      border-radius: 38px;

      padding: 13px 15px;
    }

    &, &:hover, &:focus, &:link, &:active, &:visited {
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    }

    &:hover {
      .btn-content {
        box-shadow: none;
      }
    }

    &:active {
      box-shadow: none;

      .btn-content {
        background: #40A4A3;
        box-shadow: none;
      }
    }
  }
}

.contacts-links {
  margin: 35px auto 25px;
  display: flex;
  justify-content: space-between;
  max-width: 280px;

  .contacts-link {
    width: 50px;
    height: 50px;
    background: rgba(255, 212, 0, .4);
    box-shadow: 0 3px 6px rgba(214, 194, 62, .4);
    border-radius: 50%;
    position: relative;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-top: 5px;
      margin-left: 5px;
      text-decoration: none;
      background: rgba(255, 212, 0, 1) no-repeat center center;
      color: #fff;
      font-size: 1.2rem;

      &:hover {
        background-color: #F8C82B;
      }
    }
  }
}

// Landscape
@media (min-aspect-ratio: 10/9) and (min-width: 1000px) {
  .card-container {
    padding-bottom: 0;
    background: $color-yellow-bg;
    background-size: auto 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .card-content {
    background: transparent no-repeat top 75px left 85px url($path-images + "/logo.svg");
    background-size: 50px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up("md") {
      background-size: 140px;
    }
  }

  .main-header {
    text-align: left;
    padding: 80px 75px 0;
    z-index: 1;

    .name {
      padding-top: 0;
      background: none;
      padding-left: 175px;
    }

    .role {
      padding-left: 175px;
      margin-bottom: 0;
    }
  }

  .intro {
    text-align: left;
    padding: 30px 75px 0;
    margin-left: 0;
  }

  .contacts {
    max-width: 280px;
    margin-left: 75px;
  }

  .contacts-links {
    margin: 50px 0;
  }

  .content-container {
    display: flex;
    flex-grow: 1;
    align-items: stretch;

    .content {
      flex-basis: 50%;
      flex-grow: 2;
      flex-shrink: 0;
      padding: 0;
      margin-right: 100px;
      z-index: 1;

      @include media-breakpoint-up("lg") {
        flex-basis: 45%;
      }

      .intro {
        max-width: 100%;
        padding-right: 0;
      }
    }

    .content-filler {
      flex-basis: 70%;
      flex-shrink: 1;
      flex-grow: 1;
      position: relative;
      z-index: 0;

      &::before {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        content: '';
        width: calc(100% + 300px);
        height: 200%;
        background: transparent no-repeat bottom right url($path-images + "/andrea-cristaudo-illustration.svg");
        background-size: 100% auto;
      }
    }
  }
}

</style>
